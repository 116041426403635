import { SiteData } from 'types';

export const sites: SiteData[] = [
  { colorBackground: '#161514', id: 'github', name: 'GitHub' },
  {
    colorBackground: '#ffffff',
    colorBorder: '#000',
    id: 'instagram',
    name: 'Instagram',
  },
  { colorBackground: '#0C8CCE', id: 'linkedin', name: 'Linkedin' },
  { colorBackground: '#E60019', id: 'pinterest', name: 'Pinterest' },
  { colorBackground: '#1ED760', id: 'spotify', name: 'Spotify' },
  { colorBackground: '#884BF6', id: 'twitch', name: 'Twitch' },
  { colorBackground: '#55ACEE', id: 'twitter', name: 'Twitter' },
  { colorBackground: '#FF0000', id: 'youtube', name: 'Youtube' },
];
