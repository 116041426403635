import React from 'react';
import cx from 'classnames';

import * as styles from './index.module.scss';

interface GridItemProps {
  children: string | JSX.Element | JSX.Element[];
  className?: string;
  colS?: string;
  colM?: string;
  colL?: string;
  colXl?: string;
}

export const GridItem: React.FC<GridItemProps> = ({
  children,
  className,
  colS,
  colM,
  colL,
  colXl,
}) => {
  const itemClassName = cx(className, {
    [styles[`colS${colS}` as keyof typeof styles]]: colS,
    [styles[`colM${colM}` as keyof typeof styles]]: colM,
    [styles[`colL${colL}` as keyof typeof styles]]: colL,
    [styles[`colXl${colXl}` as keyof typeof styles]]: colXl,
  });

  return <div className={itemClassName}>{children}</div>;
};
