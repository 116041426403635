import React from 'react';

import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

import * as styles from './index.module.scss';

export const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <Grid>
      <GridItem colS="15" colM="15" colL="14" colXl="13">
        A perfect place to find a perfect username on the most important social
        networks in the world.
      </GridItem>
      <GridItem
        className={styles.signature}
        colS="15"
        colM="15"
        colL="14"
        colXl="13"
      >
        Made by Invisible Guys
      </GridItem>
    </Grid>
  </footer>
);
