import React, { useRef, useCallback, useState, FormEvent } from 'react';

import styles from './index.module.scss';

interface SearchFieldProps {
  onSubmit: (value: string | undefined) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({ onSubmit }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showButton, setShowButton] = useState(false);
  const onChange = useCallback(() => {
    setShowButton(!!inputRef.current?.value);
  }, [inputRef]);
  const onFormSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit(inputRef.current?.value);
    },
    [inputRef]
  );

  return (
    <form className={styles.form} onSubmit={onFormSubmit}>
      <svg
        className={styles.icon}
        fill="none"
        height="17"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.792 13.458a5.667 5.667 0 100-11.333 5.667 5.667 0 000 11.333zM14.875 14.875l-3.081-3.081"
          stroke="#6E6E80"
          strokeLinecap="square"
          strokeWidth="1.5"
        />
      </svg>
      <input
        className={styles.input}
        onChange={onChange}
        placeholder="enter username here"
        ref={inputRef}
        type="text"
      />
      {showButton && (
        <button className={styles.button} type="submit">
          Check
        </button>
      )}
    </form>
  );
};
