import { useState, useCallback } from 'react';
import { SiteAvailability, ApiResponse } from 'types';

const fetchWithApiKey = (siteId: string, username: string) => {
  const apiUrl = `${process.env.SITE_URL}api/check/${siteId}/${username}`;

  return fetch(apiUrl, {
    headers: {
      'x-api-key': '8ba96386-29ea-4ef5-8074-7d84913d3fe2',
    },
  });
};

export const useApi = (siteId: string) => {
  const [responseData, setResponseData] = useState<SiteAvailability>({
    id: siteId,
    loading: false,
  });

  const checkUsername = useCallback(
    (userName: string) => {
      if (responseData?.loading) {
        return;
      }

      setResponseData({
        id: siteId,
        loading: true,
      });

      fetchWithApiKey(siteId, userName)
        .then((rawResponseData) => rawResponseData.json())
        .then((jsonResponseData: ApiResponse) => {
          setResponseData({
            ...jsonResponseData,
            id: siteId,
            loading: false,
          });
        });
    },
    [setResponseData]
  );

  return {
    checkUsername,
    responseData,
  };
};
