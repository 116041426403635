import React from 'react';

interface SiteIconsProps {
  className: string;
  siteId: string;
}

export const SiteIcons: React.FC<SiteIconsProps> = ({ className, siteId }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      {siteId === 'github' && (
        <>
          <g clip-path="url(#clip0)" fill="#161514">
            <path d="M24 0C10.747 0 0 11.017 0 24.608c0 10.872 6.877 20.096 16.413 23.35 1.2.227 1.64-.534 1.64-1.184 0-.587-.023-2.525-.033-4.581-6.677 1.488-8.086-2.904-8.086-2.904-1.092-2.844-2.665-3.6-2.665-3.6-2.177-1.528.165-1.496.165-1.496 2.41.173 3.679 2.535 3.679 2.535 2.14 3.762 5.614 2.675 6.984 2.046.215-1.59.837-2.676 1.523-3.29-5.33-.623-10.934-2.733-10.934-12.162 0-2.686.937-4.881 2.473-6.605-.25-.62-1.071-3.122.232-6.512 0 0 2.016-.661 6.602 2.523 1.914-.546 3.968-.82 6.007-.828 2.04.009 4.095.282 6.013.828 4.58-3.184 6.593-2.523 6.593-2.523 1.306 3.39.484 5.893.235 6.512 1.54 1.724 2.47 3.919 2.47 6.605 0 9.452-5.614 11.533-10.959 12.142.861.764 1.628 2.261 1.628 4.557 0 3.292-.027 5.942-.027 6.753 0 .655.432 1.422 1.648 1.18C41.132 44.697 48 35.476 48 24.608 48 11.017 37.255 0 24 0z" />
            <path d="M9.16 35.162c-.052.121-.24.158-.41.075-.173-.08-.271-.245-.215-.366.052-.125.24-.16.413-.076.174.08.273.246.213.367zM10.131 36.263c-.114.108-.337.058-.489-.113-.157-.17-.186-.397-.07-.506.118-.108.334-.057.491.113.157.172.188.397.068.506zM11.076 37.666c-.147.104-.387.007-.535-.21-.147-.217-.147-.477.003-.58.149-.105.385-.011.535.204.147.22.147.48-.003.586zM12.37 39.022c-.131.147-.411.108-.616-.093-.21-.197-.268-.476-.136-.623.133-.148.414-.106.62.093.208.197.272.477.132.623zM14.155 39.81c-.058.19-.327.277-.599.196-.27-.084-.448-.307-.393-.5.056-.192.327-.283.6-.196.27.083.448.305.392.5zM16.115 39.955c.007.201-.223.368-.508.371-.286.007-.518-.156-.521-.354 0-.202.225-.367.511-.372.285-.006.518.155.518.355zM17.94 39.64c.034.195-.164.397-.447.45-.278.052-.535-.07-.57-.264-.035-.2.166-.402.444-.454.283-.05.537.068.573.267z" />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0h48v48H0z" />
            </clipPath>
          </defs>
        </>
      )}

      {siteId === 'instagram' && (
        <>
          <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="48"
            height="48"
          >
            <path
              d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#a)">
            <path
              d="M24.001 11.2c-3.476 0-3.912.015-5.278.077-1.362.063-2.293.279-3.106.595a6.27 6.27 0 00-2.268 1.476 6.284 6.284 0 00-1.477 2.267c-.318.814-.534 1.745-.595 3.107-.061 1.365-.077 1.802-.077 5.278 0 3.476.016 3.911.077 5.277.063 1.362.279 2.293.595 3.106a6.271 6.271 0 001.476 2.268 6.268 6.268 0 002.267 1.477c.814.317 1.745.532 3.107.595 1.366.062 1.802.077 5.278.077 3.476 0 3.911-.015 5.276-.077 1.363-.063 2.294-.278 3.109-.595a6.263 6.263 0 002.266-1.477 6.284 6.284 0 001.477-2.267c.315-.814.53-1.745.595-3.107.061-1.365.077-1.8.077-5.277 0-3.476-.016-3.912-.077-5.278-.064-1.363-.28-2.293-.595-3.107a6.283 6.283 0 00-1.477-2.267 6.256 6.256 0 00-2.267-1.476c-.816-.316-1.747-.532-3.11-.595-1.365-.062-1.8-.077-5.277-.077h.004zm-1.148 2.307H24c3.418 0 3.823.012 5.172.073 1.249.057 1.926.266 2.377.441.597.232 1.023.51 1.471.957.448.449.725.875.958 1.473.175.45.384 1.127.44 2.375.062 1.35.075 1.755.075 5.171 0 3.416-.013 3.822-.074 5.17-.057 1.249-.266 1.926-.441 2.377a3.959 3.959 0 01-.958 1.47 3.958 3.958 0 01-1.47.958c-.452.176-1.13.384-2.377.44-1.35.062-1.755.075-5.173.075s-3.823-.013-5.172-.074c-1.248-.058-1.925-.266-2.377-.442a3.964 3.964 0 01-1.472-.957 3.967 3.967 0 01-.958-1.471c-.175-.45-.384-1.128-.44-2.376-.062-1.35-.074-1.755-.074-5.173s.012-3.821.073-5.17c.057-1.249.266-1.926.441-2.377.232-.598.51-1.024.958-1.472a3.973 3.973 0 011.472-.958c.451-.176 1.129-.384 2.377-.441 1.18-.054 1.638-.07 4.024-.072v.003zm7.98 2.125a1.536 1.536 0 100 3.073 1.536 1.536 0 000-3.073zm-6.832 1.795a6.574 6.574 0 10.001 13.147 6.574 6.574 0 00-.001-13.147zm0 2.306a4.267 4.267 0 110 8.534 4.267 4.267 0 010-8.534z"
              fill="#000"
            />
          </g>
        </>
      )}

      {siteId === 'linkedin' && (
        <>
          <path
            d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
            fill="#0C8CCE"
          />
          <path
            d="M16.96 19.878V36.22h-5.438V19.878h5.438zm.359-5.055c0 1.569-1.181 2.824-3.078 2.824h-.035c-1.826 0-3.006-1.255-3.006-2.824C11.2 13.22 12.416 12 14.277 12c1.86 0 3.006 1.22 3.042 2.823zM36.575 26.85v9.37h-5.439v-8.743c0-2.196-.787-3.695-2.755-3.695-1.503 0-2.398 1.01-2.791 1.986-.144.35-.18.837-.18 1.325v9.127h-5.439s.072-14.808 0-16.342h5.44v2.315c.721-1.113 2.014-2.699 4.902-2.699 3.58 0 6.262 2.336 6.262 7.356z"
            fill="#fff"
          />
        </>
      )}

      {siteId === 'pinterest' && (
        <>
          <path
            d="M24 47.803c13.255 0 24-10.7 24-23.901C48 10.702 37.255 0 24 0S0 10.701 0 23.902c0 13.2 10.745 23.901 24 23.901z"
            fill="#fff"
          />
          <path
            d="M24 0C10.746 0 0 10.702 0 23.902c0 10.13 6.321 18.786 15.25 22.268-.218-1.888-.396-4.8.078-6.865.435-1.869 2.805-11.882 2.805-11.882s-.71-1.436-.71-3.541c0-3.325 1.935-5.803 4.345-5.803 2.054 0 3.042 1.534 3.042 3.364 0 2.045-1.304 5.114-1.995 7.967-.573 2.38 1.205 4.328 3.555 4.328 4.267 0 7.546-4.485 7.546-10.938 0-5.725-4.128-9.718-10.034-9.718-6.835 0-10.845 5.095-10.845 10.367 0 2.046.79 4.25 1.778 5.45a.71.71 0 01.158.688c-.178.747-.593 2.38-.672 2.715-.098.432-.355.53-.81.314-3.002-1.396-4.879-5.744-4.879-9.265 0-7.535 5.492-14.46 15.862-14.46 8.316 0 14.795 5.902 14.795 13.81 0 8.243-5.215 14.873-12.444 14.873-2.43 0-4.721-1.26-5.492-2.754l-1.5 5.685c-.534 2.085-1.996 4.682-2.984 6.275a24.194 24.194 0 007.111 1.063c13.255 0 24-10.702 24-23.902C48 10.701 37.255 0 24 0z"
            fill="#E60019"
          />
        </>
      )}

      {siteId === 'spotify' && (
        <>
          <path
            d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0z"
            fill="#1ED760"
          />
          <path
            d="M34.479 34.094a1.472 1.472 0 01-2.026.49c-5.55-3.392-12.537-4.158-20.766-2.279a1.472 1.472 0 11-.656-2.871c9.005-2.059 16.729-1.173 22.96 2.634.694.425.913 1.332.488 2.026zM37.371 27.658a1.844 1.844 0 01-2.535.607c-6.352-3.905-16.039-5.036-23.554-2.755a1.846 1.846 0 01-2.3-1.228 1.845 1.845 0 011.229-2.298c8.585-2.605 19.258-1.344 26.554 3.14a1.843 1.843 0 01.606 2.534zM37.62 20.956c-7.619-4.525-20.187-4.941-27.46-2.734a2.21 2.21 0 11-1.285-4.231c8.35-2.534 22.23-2.045 31.003 3.162a2.21 2.21 0 01-2.257 3.803z"
            fill="#fff"
          />
        </>
      )}

      {siteId === 'twitch' && (
        <>
          <path
            d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
            fill="#884BF6"
          />
          <path
            d="M12.331 11.2l-1.878 5.009v20.033h6.884V40h3.758l3.754-3.758h5.634l7.512-7.51V11.2H12.331zm2.503 2.503h20.658v13.774l-4.383 4.383h-6.885l-3.753 3.754V31.86h-5.637V13.703z"
            fill="#fff"
          />
          <path
            d="M21.72 26.227h2.504v-7.513H21.72v7.513zm6.885 0h2.504v-7.513h-2.504v7.513z"
            fill="#fff"
          />
        </>
      )}

      {siteId === 'twitter' && (
        <>
          <path
            d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
            fill="#55ACEE"
          />
          <path
            d="M23.281 19.508l.05.83-.839-.102c-3.055-.39-5.724-1.712-7.99-3.932l-1.109-1.101-.285.813c-.604 1.814-.218 3.729 1.04 5.017.672.712.521.813-.637.39-.403-.136-.756-.238-.79-.187-.117.119.286 1.661.605 2.271.437.848 1.326 1.678 2.3 2.17l.823.39-.974.016c-.94 0-.974.017-.873.373.336 1.102 1.662 2.271 3.14 2.78l1.04.356-.906.542a9.45 9.45 0 01-4.5 1.254c-.755.017-1.376.085-1.376.136 0 .17 2.048 1.118 3.24 1.491 3.576 1.102 7.823.627 11.013-1.254 2.266-1.339 4.532-4 5.59-6.576.57-1.373 1.141-3.881 1.141-5.084 0-.78.05-.882.99-1.814.555-.542 1.075-1.135 1.176-1.305.168-.322.151-.322-.705-.034-1.427.509-1.629.441-.923-.322.52-.542 1.141-1.525 1.141-1.813 0-.05-.252.034-.537.186-.302.17-.974.424-1.477.577l-.907.288-.823-.56c-.453-.305-1.09-.644-1.426-.745-.857-.238-2.166-.204-2.938.068-2.099.762-3.425 2.728-3.274 4.88z"
            fill="#fff"
          />
        </>
      )}

      {siteId === 'youtube' && (
        <>
          <path
            d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
            fill="red"
          />
          <path
            d="M36.265 18.073c-.294-1.13-1.162-2.021-2.263-2.324C32.005 15.2 24 15.2 24 15.2s-8.005 0-10.002.55c-1.101.302-1.969 1.192-2.263 2.323-.535 2.05-.535 6.327-.535 6.327s0 4.277.535 6.327c.294 1.13 1.162 2.021 2.263 2.324C15.995 33.6 24 33.6 24 33.6s8.005 0 10.002-.55c1.101-.302 1.969-1.192 2.263-2.323.535-2.05.535-6.327.535-6.327s0-4.277-.535-6.327z"
            fill="#fff"
          />
          <path d="M21.6 28.8v-8l6.4 4-6.4 4z" fill="red" />
        </>
      )}
    </svg>
  );
};
