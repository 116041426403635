import React from 'react';
import cx from 'classnames';

import styles from './index.module.scss';
import { SiteIcons } from './Icons';

interface SiteIconProps {
  colorBackground: string | undefined;
  colorBorder: string | undefined;
  isDisable: boolean;
  isLoading: boolean;
  showIcon: boolean;
  siteId: string;
}

export const SiteIcon: React.FC<SiteIconProps> = ({
  colorBackground,
  colorBorder,
  isDisable,
  isLoading,
  showIcon,
  siteId,
}) => {
  return (
    <div className={styles.wrapper}>
      <svg
        className={cx(styles.absoluteCenter, styles.loader, {
          [styles.loading]: isLoading,
        })}
        fill="none"
        height="66"
        width="66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.7.545a32.5 32.5 0 0122.452 10.708"
          stroke={colorBorder || colorBackground}
        />
      </svg>
      <SiteIcons
        className={cx(styles.absoluteCenter, styles.icon, {
          [styles.disable]: isDisable,
        })}
        siteId={siteId}
      />
      <div
        className={cx(styles.circle, {
          [styles.loading]: !showIcon,
        })}
        style={{ backgroundColor: colorBackground, borderColor: colorBorder }}
      ></div>
    </div>
  );
};
