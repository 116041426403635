import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet, HelmetProps } from 'react-helmet';

interface SEOProps {
  description?: string;
  lang?: string;
}

export const HeadSeo: React.FC<SEOProps & Pick<HelmetProps, 'meta'>> = ({
  description,
  lang = 'en',
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            image {
              height
              src
              width
            }
            title
            url
          }
        }
      }
    `
  );

  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={siteMetadata.title}
      meta={[
        {
          content: metaDescription,
          name: 'description',
        },
        {
          content: siteMetadata.title,
          property: 'og:title',
        },
        {
          content: metaDescription,
          property: 'og:description',
        },
        {
          content: 'website',
          property: 'og:type',
        },
        {
          content: siteMetadata.url,
          property: 'og:url',
        },

        {
          property: 'og:image:type',
          content: 'image/png',
        },
        {
          property: 'og:image',
          content: siteMetadata.image.src,
        },
        {
          property: 'og:image:height',
          content: siteMetadata.image.height,
        },
        {
          property: 'og:image:width',
          content: siteMetadata.image.width,
        },
        {
          content: 'summary',
          name: 'twitter:card',
        },
        {
          content: siteMetadata.author,
          name: 'twitter:creator',
        },
        {
          content: siteMetadata.title,
          name: 'twitter:title',
        },
        {
          content: metaDescription,
          name: 'twitter:description',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ]}
    />
  );
};
