import React from 'react';
import cx from 'classnames';

import * as styles from './index.module.scss';

interface GridProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const Grid: React.FC<GridProps> = ({ children, className }) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};
