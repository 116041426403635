import React, { useCallback, useState } from 'react';

import { sites } from 'constants/sites';

import { Footer } from 'components/Footer';
import { HeadSeo } from 'components/HeadSeo';
import { SearchField } from 'components/SearchField';
import { SiteCheck } from 'components/SiteCheck';

import 'styles/index.scss';
import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

interface IndexPageProps {
  location: {
    hash: string;
  };
}

const IndexPage: React.FC<IndexPageProps> = () => {
  const [username, setUsername] = useState('');
  const onSubmit = useCallback(
    (value: string | undefined) => {
      if (value) {
        setUsername(value);
      }
    },
    [setUsername]
  );

  return (
    <>
      <HeadSeo />
      <SearchField onSubmit={onSubmit} />

      <Grid>
        {sites.map((site) => (
          <GridItem key={site.id}>
            <SiteCheck
              colorBackground={site.colorBackground}
              colorBorder={site.colorBorder}
              name={site.name}
              siteId={site.id}
              username={username}
            />
          </GridItem>
        ))}
      </Grid>

      <Footer />
    </>
  );
};

export default IndexPage;
