import React, { useEffect } from 'react';
import cx from 'classnames';

import { useApi } from 'hooks/useApi';
import { SiteIcon } from 'components/SiteIcon';

import styles from './index.module.scss';

interface SiteCheckProps {
  colorBackground: string | undefined;
  colorBorder: string | undefined;
  name: string;
  siteId: string;
  username: string;
}

export const SiteCheck: React.FC<SiteCheckProps> = ({
  colorBackground,
  colorBorder,
  name,
  siteId,
  username,
}) => {
  const { checkUsername, responseData } = useApi(siteId);

  useEffect(() => {
    if (username) {
      checkUsername(username);
    }
  }, [username]);

  const isDisable =
    responseData.available !== undefined && !responseData.available;
  const showIcon =
    responseData.available !== undefined || responseData.error !== undefined;

  return (
    <>
      <SiteIcon
        colorBackground={colorBackground}
        colorBorder={colorBorder}
        isDisable={isDisable}
        isLoading={responseData.loading}
        showIcon={showIcon}
        siteId={siteId}
      />
      <p
        className={cx(styles.text, {
          [styles.disabled]: isDisable,
        })}
      >
        {name}
      </p>
    </>
  );
};
